import React from 'react'
//img
import Data from './Data'
import Schedule from './schedule/Schedule'
import SocialMedia from './SocialMedia'
import PaymentMethods from './payment/PaymentMethods'
import { useSelector } from 'react-redux'
import PrinterConfig from './PrinterConfig'
import Seo from './seo/Seo'

const Settings = () => {

  const {cuota} = useSelector(state => state.log)

  return (
    <>
      <div>
        <div className={`tabsSettings ${cuota === 2 ? 'corp': ''} ${cuota === 3 ? 'premium': ''}`}>
          <ul className='nav nav-tabs' role="tablist">
            <li className='nav-item' role="presentation">
              <button
                className="nav-link active"
                id="settings1-tab"
                data-bs-toggle="tab"
                data-bs-target="#settings1-tab-pane"
                type="button"
                role="tab"
                aria-controls="settings1-tab-pane"
                aria-selected="true"
              >
                Global
              </button>
            </li>
            
            <li className='nav-item' role="presentation">
              <button
                className="nav-link"
                id="settings4-tab"
                data-bs-toggle="tab"
                data-bs-target="#settings4-tab-pane"
                type="button"
                role="tab"
                aria-controls="settings4-tab-pane"
                aria-selected="false"
              >
                Medios de pago
              </button>
            </li>
            <li className='nav-item' role="presentation">
              <button
                className="nav-link"
                id="settings2-tab"
                data-bs-toggle="tab"
                data-bs-target="#settings2-tab-pane"
                type="button"
                role="tab"
                aria-controls="settings2-tab-pane"
                aria-selected="false"
              >
                Domicilio y horarios
              </button>
            </li>
            <li className='nav-item' role="presentation">
              <button
                className="nav-link"
                id="settings3-tab"
                data-bs-toggle="tab"
                data-bs-target="#settings3-tab-pane"
                type="button"
                role="tab"
                aria-controls="settings3-tab-pane"
                aria-selected="false"
              >
                Redes sociales
              </button>
            </li>
            <li className='nav-item' role="presentation">
              <button
                className="nav-link"
                id="settings5-tab"
                data-bs-toggle="tab"
                data-bs-target="#settings5-tab-pane"
                type="button"
                role="tab"
                aria-controls="settings5-tab-pane"
                aria-selected="false"
              >
                Ticket
              </button>
            </li>
            <li className='nav-item' role="presentation">
              <button
                className="nav-link"
                id="settings6-tab"
                data-bs-toggle="tab"
                data-bs-target="#settings6-tab-pane"
                type="button"
                role="tab"
                aria-controls="settings6-tab-pane"
                aria-selected="false"
              >
                Ajustes web
              </button>
            </li>
          </ul>
        </div>
        <section className="px-0 px-md-2 pt-2 pb-5 mb-5">
          <div className="container-fluid">
           
            <div className="tab-content" id="tabSettings">
              <div
                className="tab-pane fade show active"
                id="settings1-tab-pane"
                role="tabpanel"
                aria-labelledby="settings1-tab"
                tabIndex="0"
              >
                <Data/>
              </div>
              
              <div
                className="tab-pane fade"
                id="settings4-tab-pane"
                role="tabpanel"
                aria-labelledby="settings4-tab"
                tabIndex="0"
              >
                <PaymentMethods/>
              </div>
              <div
                className="tab-pane fade"
                id="settings2-tab-pane"
                role="tabpanel"
                aria-labelledby="settings2-tab"
                tabIndex="0"
              >
                <Schedule />
              </div>
              <div
                className="tab-pane fade"
                id="settings3-tab-pane"
                role="tabpanel"
                aria-labelledby="settings3-tab"
                tabIndex="0"
              >
                <SocialMedia />
              </div>
              <div
                className="tab-pane fade"
                id="settings5-tab-pane"
                role="tabpanel"
                aria-labelledby="settings5-tab"
                tabIndex="0"
              >
                <PrinterConfig/>
              </div>
              <div
                className="tab-pane fade"
                id="settings6-tab-pane"
                role="tabpanel"
                aria-labelledby="settings6-tab"
                tabIndex="0"
              >
                <Seo/>
              </div>
            </div>

            
          </div>
        </section>

        
      </div>
    </>
  )
}

export default Settings