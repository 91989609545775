import React from 'react'
import {useSelector } from 'react-redux'
import InfoGreen from '../../InfoGreen'

const Cash = () => {

  const {paymentAccount} = useSelector(s => s.menu)
  

  return (
    <div className="bg-b1 rounded-lg p-4 mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className='d-flex align-items-center'>
          <div className="form-check form-switch justify-content-center">
            <input
              className="form-check-input me-3"
              type="checkbox"
              role="switch"
              defaultChecked={paymentAccount.a}
            />
          </div>
          <span className='text-white'>Aceptar pagos en efectivo</span>
        </div>
        <span className="optionalLabel">Opcional</span>
      </div>
      <div className="mt-3">
        <InfoGreen
          text='Ten en cuenta que los créditos FOODER se descuentan una vez se genera un pedido, esta opción la puedes desactivar si detectas competencia desleal para acabar con tu plan de créditos con una serie de pedidos en masa injustificados.'
        />
      </div>
    </div>
  )
}

export default Cash