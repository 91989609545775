import React from 'react'
import { useSelector } from 'react-redux'
import boldDisabled from '../../../../../img/dashboard/bold_disabled.svg'
import logobold from '../../../../../img/vector/bold_logo.svg'
import KeysForm from './KeysForm'




const Bold = () => {

  const {cuota} = useSelector(s => s.log)

  const production = {
    title: 'Llaves de producción',
    label1: 'Llave de identidad',
    description1: 'Usa esta llave en el código de tu página web para comenzar a recibir pagos de forma segura.',
    label2: 'Llave secreta',
    description2: 'Usa esta llave para que podamos validar los pagos hechos a través de nuestra pasarela de pagos, verificando su autenticidad y seguridad.'
  }

  const testing = {
    title: 'Llaves ambiente de pruebas',
    label1: 'Llave de identidad de pruebas',
    description1: 'Usa estas llaves para realizar una transacción de prueba. Te recomendamos no ingresar información real en los formularios de pago para no generar cobros. Acá te entregamos los datos de prueba.',
    label2: 'Llave secreta de pruebas',
    description2: ''
  }
  

  return (
    <div className="bg-b1 rounded-lg p-4 mt-3">
      <div>
        {
          (cuota === 2 || cuota === 3) ?
            (<>
              <KeysForm
                image={logobold}
                switchText="Activar pagos en Bold"
                title= "Ingresar llaves de integración"
                description="Una vez abierta tu cuenta en Bold.co ubica la sección Integraciones > Llaves de integración. Luego genera las llaves de integración. Una vez hecho este paso en la plataforma de pagos Bold, regresa aquí para ingresarlas en el mismo orden, copia y pega en los siguientes campos:"
                production={production}
                testing={testing}
              />
            </>)
            :
            (<>
              <img src={boldDisabled} alt="" className='img-fluid'/>
              <div className="row justify-content-center">
                <div className="col-md-7 text-center mt-3">
                  <button
                    className="activePlan"
                    data-bs-target='#modalUpdatePlan'
                    data-bs-toggle="modal"
                    type='button'
                  >ACTUALIZAR PLAN</button>
                </div>
              </div>
            </>)
        }
      </div>
    </div>
  )
}

export default Bold