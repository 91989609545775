import React, { useState } from 'react'

const KeysForm = ({image, title, switchText, description,production,testing}) => {

  const [hide1, setHidde1] = useState(true)
  const [hide2, setHidde2] = useState(true)
  const [hide3, setHidde3] = useState(true)
  const [hide4, setHidde4] = useState(true)

  return (
    <form>
      <div className="row">

        <div className="col-12 mb-3">
          <div className="row">
            <div className="col-md-10 align-self-center">
              <div className='d-flex align-self-center'>
                <div className="form-check form-switch justify-content-center align-items-center">
                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    role="switch"
                  />
                </div>
                <span className='text-white d-flex align-items-center'>{switchText}</span>
              </div>
            </div>
            <div className="col-md-2 align-items-center">
              <img src={image} alt="" className='img-fluid'/>
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr className='mt-0'/>
        </div>

        <div className="col-md-12  align-self-center">
          <h5 className='text-apple mb-3 text-center'>{title}</h5>
          <p>{description}</p>
        </div>
        <div className="col-md-12">
          <h6 className='text-apple mb-3'>{production.title}</h6>
          <div className="bg-white p-3 rounded-3 text-dark">
            <div>
              <h6 className='mb-2'>{production.label1}</h6>
              <p>{production.description1}</p>
              <div className="inputPass">
                <input 
                  type={hide1 ? 'password': 'text'}
                />
                <button type='button' className={hide1 ? 'hide': ''} onClick={()=> setHidde1(!hide1)}/>
              </div>
            </div>
            <div>
              <h6 className='mb-2'>{production.label2}</h6>
              <p>{production.description2}</p>
              <div className="inputPass">
                <input 
                  type={hide2 ? 'password': 'text'}
                />
                <button type='button' className={hide2 ? 'hide': ''} onClick={()=> setHidde2(!hide2)}/>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <h6 className='text-apple my-3'>{testing.title}</h6>
          <div className="bg-white p-3 rounded-3 text-dark">
            <div>
              <h6 className='mb-2'>{testing.label1}</h6>
              <p>{testing.description1}</p>
              <div className="inputPass">
                <input 
                  type={hide3 ? 'password': 'text'}
                />
                <button type='button' className={hide3 ? 'hide': ''} onClick={()=> setHidde3(!hide3)}/>
              </div>
            </div>
            <div>
              <h6 className='mb-2'>{testing.label2}</h6>
              <p>{testing.description2}</p>
              <div className="inputPass">
                <input 
                  type={hide4 ? 'password': 'text'}
                />
                <button type='button' className={hide4 ? 'hide': ''} onClick={()=> setHidde4(!hide4)}/>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </form>
  )
}

export default KeysForm